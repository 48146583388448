<template>
  <bank-transfers-report></bank-transfers-report>
</template>

<script>
import BankTransfersReport from '@/components/Reports/Accounting/BankTransfersReport.vue'

export default {
  components: { BankTransfersReport },
}
</script>
